<script>

  let filter = '';
  for (let deviceJid of Object.keys(Devices.deviceList)) {
    let deviceNode = Strophe.getNodeFromJid(deviceJid);
    if (filter !== '') {
      filter += ' or ';
    }
    filter += `jid:"${deviceNode}"`;
  }

  let container;
  let iframe;

  function resizeIframe () {
    let previousHeight = 0;
    let interval = setInterval(() => {
      let height = iframe.contentDocument.body.scrollHeight;
      container.style.height = `${height}px`;
      iframe.style.height = `${height}px`;
      if (height > 100 && height !== previousHeight) {
        clearInterval(interval);
      }
      previousHeight = height;
    }, 500);
  }
</script>

<div bind:this={container} class="device_full" id="history-stats-frame-wrapper">
    <iframe bind:this={iframe}
            id="history-stats-frame"
            name="history-stats-frame"
            src="/app/dashboards#/view/b8063160-d495-11e9-aca3-d1d477e139cd?embed=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-3d,mode:relative,to:now))&_a=(query:(language:kuery,query:'{filter}'))"
            title="Displays Kibana dashboard containing information about this device"
    >
    </iframe>
</div>

<style>
    #history-stats-frame-wrapper {
        position: relative;
        height: 4000px;
        overflow-y: auto;
    }

    #history-stats-frame {
        width: 100%;
        height: 100%;
        position: absolute;
        border: none;
    }
</style>